// ----- INI globe animation + change theme
import * as THREE from 'three';

let savedTheme;

// Configurações das luzes com base no tema
const getLightsConfig = (theme) => {
  if (theme === 'dark') {
    return {
      light1: { color: 0xF5F3ED, intensity: 1, position: { x: 1, y: 0, z: 0 } },
      light2: { color: 0x4A4A4A, intensity: 0.5, position: { x: -1, y: 0, z: 0 } },
    };
  } else {
    return {
      light1: { color: 0xf5f3ed, intensity: 130, position: { x: 10, y: 50, z: 0 } },
      light2: { color: 0xf5f3ed, intensity: 100, position: { x: -1, y: 0, z: 0 } },
    };
  }
};

// Array para armazenar renderers e cenas para o redimensionamento
const scenes = [];
const renderers = [];

// Carregar tema salvo
const loadTheme = () => {
  savedTheme = localStorage.getItem('theme') || 'light';
  document.body.classList.toggle('dark-mode', savedTheme === 'dark');
};
loadTheme();

// Função para criar cena e luzes
function createScene(canvas) {
  const lightsConfig = getLightsConfig(savedTheme);
  const { width, height } = canvas.getBoundingClientRect();

  const renderer = new THREE.WebGLRenderer({ canvas, alpha: true });
  renderer.setPixelRatio(window.devicePixelRatio);
  renderer.setSize(width, height);

  const scene = new THREE.Scene();
  const camera = new THREE.PerspectiveCamera(45, width / height, 1, 1000);
  camera.position.set(0, 0, 10);
  camera.rotation.set(0, 0, -0.35);

  const ambientLight = new THREE.AmbientLight(0xffffff, 0.05);
  scene.add(ambientLight);

  const directionalLight1 = new THREE.DirectionalLight(lightsConfig.light1.color, lightsConfig.light1.intensity);
  directionalLight1.position.set(
    lightsConfig.light1.position.x,
    lightsConfig.light1.position.y,
    lightsConfig.light1.position.z
  );
  scene.add(directionalLight1);

  const directionalLight2 = new THREE.DirectionalLight(lightsConfig.light2.color, lightsConfig.light2.intensity);
  directionalLight2.position.set(
    lightsConfig.light2.position.x,
    lightsConfig.light2.position.y,
    lightsConfig.light2.position.z
  );
  scene.add(directionalLight2);

  const sphereGeometry = new THREE.SphereGeometry(3.5, 164, 164);
  const texture = new THREE.TextureLoader().load(
    savedTheme === 'dark' ? './resources/images/bg_sphere-white.png' : './resources/images/bg_sphere-black.png'
  );
  texture.wrapS = THREE.RepeatWrapping;
  texture.wrapT = THREE.RepeatWrapping;
  texture.repeat.set(2, 2);

  const gridMaterial = new THREE.MeshStandardMaterial({
    map: texture,
    transparent: true,
  });

  const firstObject = new THREE.Mesh(sphereGeometry, gridMaterial);
  firstObject.rotation.set(0.65, Math.PI / 4, 0);
  scene.add(firstObject);

  function animate() {
    firstObject.rotation.y += 0.004;
    renderer.render(scene, camera);
    requestAnimationFrame(animate);
  }
  animate();

  // Armazena a cena e o renderer para redimensionamento
  scenes.push({ directionalLight1, directionalLight2, gridMaterial });
  renderers.push({ renderer, camera, canvas });
}

// Função para atualizar todas as luzes da cena
function updateAllSceneLights(theme) {
  const lightsConfig = getLightsConfig(theme);
  scenes.forEach(({ directionalLight1, directionalLight2, gridMaterial }) => {
    directionalLight1.color.setHex(lightsConfig.light1.color);
    directionalLight1.intensity = lightsConfig.light1.intensity;
    directionalLight1.position.set(
      lightsConfig.light1.position.x,
      lightsConfig.light1.position.y,
      lightsConfig.light1.position.z
    );
    directionalLight2.color.setHex(lightsConfig.light2.color);
    directionalLight2.intensity = lightsConfig.light2.intensity;
    directionalLight2.position.set(
      lightsConfig.light2.position.x,
      lightsConfig.light2.position.y,
      lightsConfig.light2.position.z
    );

    // Atualiza a textura de acordo com o tema
    const newTexture = new THREE.TextureLoader().load(
      theme === 'dark' ? './resources/images/bg_sphere-white.png' : './resources/images/bg_sphere-black.png'
    );
    newTexture.wrapS = THREE.RepeatWrapping;
    newTexture.wrapT = THREE.RepeatWrapping;
    newTexture.repeat.set(2, 2);
    gridMaterial.map = newTexture;
    gridMaterial.needsUpdate = true;
  });
}

// Função para alternar o tema
document.getElementById('button__contrast').addEventListener('click', (event) => {
  event.preventDefault();
  document.body.classList.toggle('dark-mode');
  savedTheme = document.body.classList.contains('dark-mode') ? 'dark' : 'light';
  localStorage.setItem('theme', savedTheme);
  updateAllSceneLights(savedTheme);
});

// Função para redimensionar todas as cenas
function onResize() {
  //console.log(window.innerWidth)
  renderers.forEach(({ renderer, camera, canvas }) => {
    //const { width, height } = canvas.getBoundingClientRect();
    /* console.log(renderer)
    console.log(camera)
    console.log(canvas) */
    if(canvas.getAttribute("id") === 'canvashero'){
      if(window.innerWidth < 768) {
        console.log('300')
        renderer.setSize(300, 300);
        camera.aspect = 300 / 300;
      } else if(window.innerWidth > 768 && window.innerWidth < 1280) {
        console.log('450')
        renderer.setSize(450, 450);
        camera.aspect = 450 / 450;
      } else {
        console.log('550')
        renderer.setSize(550, 550);
        camera.aspect = 550 / 550;
      }
    }
    
    camera.updateProjectionMatrix();
  });
}

// Adiciona listener de redimensionamento
window.addEventListener('resize', onResize);

// Inicialização das cenas
document.querySelectorAll('.globe-canvas').forEach(createScene);

// ----- END globe animation + change theme





// ----- INI copy to clipboard
const mailtoLinks = document.querySelectorAll('.link--email');

// Function to show tooltip
const showTooltip = (element, message) => {
  // Create a tooltip element
  const tooltip = document.createElement('div');
  tooltip.className = 'tooltip';
  tooltip.textContent = message;

  // Append tooltip to the body
  document.body.appendChild(tooltip);

  // Position the tooltip near the clicked element
  const rect = element.getBoundingClientRect();
  tooltip.style.left = `${rect.left + window.scrollX + 16}px`;
  tooltip.style.top = `${rect.bottom + window.scrollY + 8}px`;

  // Show the tooltip
  tooltip.classList.add('visible');

  // Hide the tooltip after 2 seconds
  setTimeout(() => {
    tooltip.classList.remove('visible');
    document.body.removeChild(tooltip);
  }, 2000);
};

// Loop through each element and add the click event listener
mailtoLinks.forEach(link => {
  link.addEventListener('click', function(event) {
    event.preventDefault(); // Prevent the default action of opening the mail client

    // Get the mailto link
    const mailtoLink = event.target.href;

    // Copy the mailto link to the clipboard
    navigator.clipboard.writeText(mailtoLink).then(() => {
      // Show the tooltip near the clicked link
      showTooltip(event.target, 'Copied to clipboard!');
    }).catch(err => {
      // Handle errors
      console.error('Failed to copy link: ', err);
    });
  });
});

// ----- END copy to clipboard





// ----- INI we are online
// holidays list
const holidays = [
  '2024-01-01',
  '2024-12-25', 
];

// check if today is a holiday
const isHoliday = () => {
  const now = new Date();
  const today = now.getFullYear() + '-' +
                String(now.getMonth() + 1).padStart(2, '0') + '-' +
                String(now.getDate()).padStart(2, '0');
  return holidays.includes(today);
};

// current hour in Irish Standard Time (IST)
const getFormattedIstTime = () => {
  const now = new Date();
  
  // Format the date to Irish time (Europe/Dublin)
  //const options = { timeZone: 'Europe/Dublin', hour: '2-digit', minute: '2-digit', second: '2-digit', hour12: false };
  const options = { timeZone: 'Europe/Dublin', hour: '2-digit', minute: '2-digit', hour12: false };
  const formatter = new Intl.DateTimeFormat('en-GB', options);
  
  return formatter.format(now);
};

// check if we are online
const checkOnlineStatus = () => {
  const now = new Date();

  // Get time in Irish time zone
  const options = { timeZone: 'Europe/Dublin', weekday: 'long', hour: '2-digit', minute: '2-digit', hour12: false };
  const formatter = new Intl.DateTimeFormat('en-GB', options);
  const dateParts = formatter.formatToParts(now);
  
  // Extract relevant parts
  const day = dateParts.find(part => part.type === 'weekday').value;
  const hours = parseInt(dateParts.find(part => part.type === 'hour').value, 10);
  const minutes = parseInt(dateParts.find(part => part.type === 'minute').value, 10);

  // Check if it's a weekday (Monday to Friday)
  const isWeekday = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday'].includes(day);

  // Check if it's between 9:00 and 17:30 IST
  const isInTimeRange = (hours > 9 || (hours === 9 && minutes >= 0)) &&
                        (hours < 17 || (hours === 17 && minutes <= 30));
  
  const statusIcon = document.querySelector('.online__status');
  const statusText = document.querySelector('.status-text');
  const istTime = document.querySelector('.ist-time');

  // Show online status
  if (isWeekday && isInTimeRange && !isHoliday()) {
    statusIcon.classList.add('online'); 
    statusText.textContent = 'Working';
  } else {
    statusIcon.classList.remove('online');
    statusText.textContent = 'Exploring';
  }

  istTime.textContent = `IRE ${getFormattedIstTime()}`;
};

checkOnlineStatus();
setInterval(checkOnlineStatus, 60000); 

const showHideStatus = (event) => {
  event.preventDefault();
  const boxToaster = document.querySelector('.box-group__online-status');
  boxToaster.classList.toggle('is-visible');
};
document.getElementById('button__status').addEventListener('click', showHideStatus);
const btnCloseToaster = document.querySelectorAll('.button__close-toaster');

for (let i = 0; i < btnCloseToaster.length; i++) {
  btnCloseToaster[i].addEventListener('click', showHideStatus);
}
// ----- END we are online






// ----- INI mobile menu
let currentWindowWidth
let lastWindowWidth
const getWindowSize = () => {
  currentWindowWidth = window.innerWidth
  if (currentWindowWidth >= 1024 && lastWindowWidth < 1024) { }
  lastWindowWidth = currentWindowWidth
}

const showHideMenu = () => {
  document.getElementById('menu__navigation').classList.toggle('is-visible')
}

window.addEventListener('resize', getWindowSize)
window.addEventListener('load', getWindowSize)
document.getElementById('button__menu').addEventListener('click', showHideMenu)
// ----- END mobile menu





// ----- INI services accordion
const btnTitleCardServices = document.querySelectorAll('.card__title--accordion')

const showHideCard = (event) => {
  const targetElement = event.target // current clicked element
  const parentElement = targetElement.parentNode
  parentElement.classList.toggle('is-active')
}

for (let i = 0; i < btnTitleCardServices.length; i++) {
  btnTitleCardServices[i].addEventListener('click', showHideCard)
}
// ----- END services accordion




// ----- INI check content height
// Get elements
const header = document.querySelector('header');
const main = document.querySelector('main');
const footer = document.querySelector('footer');

// Get the height of each element
const windowHeight = window.innerHeight;
const contentHeight = header.offsetHeight + main.offsetHeight + footer.offsetHeight;

const checkContentHeight = () => {
  const windowHeight = window.innerHeight;
  const contentHeight = header.offsetHeight + main.offsetHeight + footer.offsetHeight;
  
  if (windowHeight > contentHeight) {
    footer.classList.add('p--fixed');
  } else {
    footer.classList.remove('p--fixed');
  }
};

window.addEventListener('load', checkContentHeight);
window.addEventListener('resize', checkContentHeight);
// ----- END check content height




// ----- INI footer logo animation
  const parallaxItem = document.querySelector('.image-logo-footer');
  window.addEventListener('scroll', () => {
      const pageHeight = document.documentElement.scrollHeight - window.innerHeight;      
      const scrollPercentage = 1 - (window.scrollY / pageHeight);
      const offset = scrollPercentage * 550;   
      parallaxItem.style.transform = `translate3d(0, ${offset}px, 0)`;
  });
// ----- END footer logo animation




